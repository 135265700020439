<template>
  <nav class="main-nav">
    <div
      class="menu-toggle"
      @click="toggleMenu"
    >
      <div class="hamburger-icon">
        <span />
        <span />
        <span />
      </div>
    </div>
    <div 
      class="menu-wrapper"
      :class="{ 'menu-open': isMenuOpen }"
    >
      <ul class="menu">
        <MenuItem
          v-for="item in topLevelItems"
          :key="item.id"
          :item="item"
          :is-mobile="isMobile"
          :is-main-level="true"
          @close-menu="closeMenu"
          @auth-action="handleAuthAction"
        />
      </ul>
    </div>
  </nav>
</template>

<script>
import { ref, computed, onMounted, onUnmounted, provide, watch } from 'vue'
import { useRouter } from 'vue-router'
import { getAuth, signOut } from 'firebase/auth'
import menuStructure from '@/assets/menu-structure.json'
import MenuItem from './MenuItem.vue'

export default {
  name: 'MainMenu',
  components: {
    MenuItem,
  },
  props: {
    isAuthenticated: {
      type: Boolean,
      required: true
    },
    isStaff: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    const isMenuOpen = ref(false)
    const isMobile = ref(false)
    const router = useRouter()
    const openSubmenuIds = ref(new Set())
    const mainLevelIds = ref(new Set())

    const updateIsMobile = () => {
      isMobile.value = window.innerWidth <= 768
    }

    onMounted(() => {
      updateIsMobile()
      window.addEventListener('resize', updateIsMobile)
    })

    onUnmounted(() => {
      window.removeEventListener('resize', updateIsMobile)
    })

    const filteredMenuItems = computed(() => {
      console.log('MainMenu - Filtering menu items:', { isAuthenticated: props.isAuthenticated, isStaff: props.isStaff })
      let items = menuStructure.map(item => {
        if (!item.enabled) {
          console.log(`MainMenu - Item ${item.id} is disabled`)
          return null
        }
        
        if (item.id === 'authentication') {
          console.log('MainMenu - Processing authentication item')
          if (item.children && Array.isArray(item.children)) {
            const authChildren = item.children.filter(child => {
              if (child.id === 'login' || child.id === 'logout') {
                const shouldShow = child.id === (props.isAuthenticated ? 'logout' : 'login')
                console.log(`MainMenu - ${child.id} visibility:`, shouldShow)
                return shouldShow
              }
              return false
            })
            if (authChildren.length > 0) {
              return { ...authChildren[0], id: 'authentication' }
            }
          }
          console.log('MainMenu - Authentication item has no valid children')
          return null
        }
        
        if (props.isStaff && item.staff) {
          console.log(`MainMenu - Showing staff item: ${item.id}`)
          return item
        }
        
        if (props.isAuthenticated && item.member) {
          console.log(`MainMenu - Showing member item: ${item.id}`)
          return item
        }
        
        if (item.public) {
          console.log(`MainMenu - Showing public item: ${item.id}`)
          return item
        }
        
        console.log(`MainMenu - Hiding item: ${item.id}`)
        return null
      }).filter(Boolean) // Remove null items

      // Manually add login/logout option if not present
      const hasAuthItem = items.some(item => item.id === 'authentication')
      if (!hasAuthItem) {
        console.log('MainMenu - Manually adding authentication item')
        items.push({
          id: 'authentication',
          text: props.isAuthenticated ? 'Logga Ut' : 'Logga In',
          icon: props.isAuthenticated ? 'fad fa-sign-out-alt' : 'fad fa-sign-in-alt',
          'router-link': props.isAuthenticated ? '/logout' : '/login',
          order: 99 // Adjust this value to position the item in the menu
        })
      }

      return items
    })

    const buildMenuTree = (items, parentId = null) => {
      return items
        .filter(item => item.parent === parentId || (!item.parent && parentId === null))
        .map(item => ({
          ...item,
          children: buildMenuTree(items, item.id)
        }))
        .sort((a, b) => (a.order || 0) - (b.order || 0));
    };

    const topLevelItems = computed(() => {
      console.log('MainMenu - Building top level items')
      const items = buildMenuTree(filteredMenuItems.value);
      console.log('MainMenu - Top level items:', items.map(item => ({ id: item.id, text: item.text, children: item.children ? item.children.length : 0 })))
      return items
    })

    const toggleMenu = () => {
      isMenuOpen.value = !isMenuOpen.value
    }

    const closeMenu = () => {
      isMenuOpen.value = false
      openSubmenuIds.value.clear()
    }

    const handleAuthAction = async (item) => {
      console.log('MainMenu - Handling auth action:', item.id)
      if (item.id === 'authentication' && props.isAuthenticated) {
        try {
          const auth = getAuth()
          await signOut(auth)
          console.log('MainMenu - User signed out successfully')
          router.push('/login')
        } catch (error) {
          console.error('MainMenu - Error signing out:', error)
        }
      } else {
        router.push(item['router-link'] || '/login')
      }
      closeMenu()
    }

    const toggleSubmenu = (itemId, isMainLevel) => {
      if (isMainLevel) {
        // Close all other main-level dropdowns
        openSubmenuIds.value.forEach(id => {
          if (mainLevelIds.value.has(id) && id !== itemId) {
            openSubmenuIds.value.delete(id)
          }
        })
      }

      if (openSubmenuIds.value.has(itemId)) {
        openSubmenuIds.value.delete(itemId)
      } else {
        openSubmenuIds.value.add(itemId)
      }

      if (isMainLevel) {
        mainLevelIds.value.add(itemId)
      }
    }

    const isSubmenuOpen = (itemId) => openSubmenuIds.value.has(itemId)

    provide('openSubmenuIds', openSubmenuIds)
    provide('toggleSubmenu', toggleSubmenu)
    provide('isSubmenuOpen', isSubmenuOpen)
    provide('handleAuthAction', handleAuthAction)

    watch(() => props.isAuthenticated, (newValue) => {
      console.log('MainMenu - isAuthenticated changed:', newValue)
    }, { immediate: true })

    watch(() => props.isStaff, (newValue) => {
      console.log('MainMenu - isStaff changed:', newValue)
    }, { immediate: true })

    return {
      isMenuOpen,
      topLevelItems,
      toggleMenu,
      closeMenu,
      isMobile,
      openSubmenuIds,
      mainLevelIds,
      handleAuthAction,
    }
  }
}
</script>

<style scoped>
.main-nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #333;
  z-index: 1000;
  height: 50px;
}

.hamburger-icon {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 24px;
  height: 18px;
  padding: 0;
  margin: 16px;
  background: transparent;
  border: none;
  cursor: pointer;
}

.hamburger-icon span {
  display: block;
  width: 100%;
  height: 2px;
  background-color: white;
  transition: all 0.3s ease;
}

.menu {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  height: 100%;
}

.menu-item {
  position: relative;
  list-style: none;
  width: 100%;
  background-color: #333;
}

.menu-item-content {
  display: flex;
  align-items: center;
  color: white;
  padding: 12px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
  text-decoration: none;
  opacity: 1;
  visibility: visible;
  white-space: nowrap;           /* Prevents line breaks */
  overflow: hidden;              /* Hide any overflow */
  text-overflow: ellipsis;       /* Optionally add ellipsis if text is too long */
  font-size: clamp(0.75rem, 2vw, 1rem); /* Responsive font size */
}

.menu-item-content:hover {
  background-color: #444;
}

.menu-toggle {
  display: none;
}

.menu-container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 80px;
  background-color: var(--v-background-base, #ffffff);
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  z-index: 100;
  display: flex;
  flex-direction: column;
  transition: width 0.3s ease;
  overflow: hidden;
}

/* Mobile menu styles */
@media (max-width: 768px) {
  .menu-toggle {
    display: block;
    color: white;
    padding: 0;
    cursor: pointer;
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
  }

  .hamburger-icon {
    display: flex;
  }

  .menu-wrapper {
    display: none;
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #333;
    z-index: 1000;
  }

  .menu-wrapper.menu-open {
    display: block;
  }

  .menu {
    height: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 50px);
  }

  .menu-item {
    border-top: 1px solid rgba(255,255,255,0.1);
    display: block !important;
    opacity: 1 !important;
    visibility: visible !important;
    width: 100%;
    flex-shrink: 0; /* Prevent items from shrinking */
  }

  .menu-item:first-child {
    border-top: none;
  }

  .menu-item-content {
    padding: 15px 20px;
    width: 100%;
    justify-content: flex-start;
    display: flex !important;
    opacity: 1 !important;
    visibility: visible !important;
    color: white !important;
  }

  /* Remove conflicting styles */
  .menu-container,
  .menu-content {
    display: none;
  }
}

.menu-open ~ .menu-toggle .hamburger-icon span:first-child {
  transform: rotate(45deg) translate(5px, 5px);
}

.menu-open ~ .menu-toggle .hamburger-icon span:nth-child(2) {
  opacity: 0;
}

.menu-open ~ .menu-toggle .hamburger-icon span:last-child {
  transform: rotate(-45deg) translate(5px, -5px);
}

/* Fix for nested scrollable areas */
.v-list {
  overflow: visible !important;
}

/* Ensure menu items are clickable */
.menu-item {
  position: relative;
  z-index: 1;
}
</style>
