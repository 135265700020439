<template>
  <div
    id="app"
    :key="forceUpdate"
  >
    <MetaManager />
    <div v-if="isAuthReady">
      <MainMenu
        :is-authenticated="isAuthenticated"
        :is-staff="isStaff"
      />
      <div class="content">
        <div
          ref="backgroundLogo"
          class="background-logo"
          :style="{
            backgroundImage: backgroundLogoUrl ? `url(${backgroundLogoUrl})` : 'none',
            display: backgroundLogoUrl ? 'block' : 'none'
          }"
        >
          <img
            :src="backgroundLogoUrl"
            alt="Background Logo"
            class="background-logo-img"
            width="512"
            height="512"
            loading="eager"
            @load="handleImageLoad"
            @error="handleImageError"
          />
        </div>
        <div class="content-wrapper">
          <router-view v-slot="{ Component }">
            <keep-alive>
              <component :is="Component" />
            </keep-alive>
          </router-view>
        </div>
      </div>
    </div>
    <div v-else>
      Loading...
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch, onUnmounted } from 'vue'
import { getAuth, onAuthStateChanged, getRedirectResult } from 'firebase/auth'
import MainMenu from '@/components/MainMenu.vue'
import MetaManager from '@/components/MetaManager.vue'
import { fcmService } from '@/services/fcmService'
import { api } from '@/services/api'
import { useUserState } from '@/composables/useAuth'
import { useMainStore } from '@/store'

const store = useMainStore()
const { isStaff } = useUserState()
const isAuthReady = ref(false)
const forceUpdate = ref(0)

const isAuthenticated = computed(() => store.isAuthenticated)

console.log('Initial isStaff in App.vue:', isStaff.value)

const backgroundLogoUrl = computed(() => {
  return '/logo-icon.webp'
})

const handleImageLoad = () => {
  const bgElement = document.querySelector('.background-logo')
  if (bgElement) {
    bgElement.style.opacity = '0.2'
    bgElement.style.display = 'block'
  }
}

const handleImageError = () => {
  console.error('Failed to load logo from:', backgroundLogoUrl.value)
}

onMounted(async () => {
  try {
    console.log('App.vue mounted, initializing...');
    await fcmService.init();
    const auth = getAuth();
    
    // Handle redirect result
    console.log('Checking for redirect result in App.vue');
    const result = await getRedirectResult(auth);
    if (result) {
      console.log('Redirect result found in App.vue:', result);
      await handleUserAuthentication(result.user);
    } else {
      console.log('No redirect result found in App.vue');
    }

    onAuthStateChanged(auth, async (user) => {
      console.log('Auth state changed:', user ? 'User logged in' : 'User logged out');
      if (user) {
        await handleUserAuthentication(user);
      } else {
        await store.setUser(null);
      }
      isAuthReady.value = true;
      console.log('Auth ready state set to true');
    });

    // Set auth ready to true even if no auth state change
    setTimeout(() => {
      if (!isAuthReady.value) {
        console.log('Setting auth ready after timeout');
        isAuthReady.value = true;
      }
    }, 2000);

    // Initialize theme system
    store.initializeTheme()

  } catch (error) {
    console.error('Error initializing app:', error);
    // Set auth ready even if there's an error
    isAuthReady.value = true;
  }
})

const handleUserAuthentication = async (user) => {
  try {
    console.log('Handling user authentication in App.vue:', user)
    console.log('User email:', user.email, 'User UID:', user.uid)
    await store.fetchCurrentUser(user.email, user.uid)
    console.log('User fetched and set in store:', store.user)
    console.log('isStaff after fetching:', isStaff.value)
    await initializeFCM(store.user.id)
  } catch (error) {
    console.error('Error handling user authentication:', error)
  }
}

watch(() => store.isAuthenticated, () => {
  forceUpdate.value++
})

const initializeFCM = async (userId, token = null) => {
  if (!token) {
    token = await fcmService.getToken();
  }
  if (token) {
    if (token !== store.user.fcmToken) {
      try {
        await api.updateUserFCMToken(userId, token);
        store.updateUserFCMToken(token);
      } catch (error) {
        console.error('Failed to send FCM token to server:', error);
      }
    }
  }

  fcmService.onMessageListener().then((payload) => {
    console.log('Received foreground message:', payload);
    // Handle the received message here
  });
}

let tokenRefreshInterval;

const checkAndUpdateToken = async () => {
  if (store.user && store.user.id) {
    const newToken = await fcmService.getToken();
    if (newToken && newToken !== store.user.fcmToken) {
      await initializeFCM(store.user.id, newToken);
    }
  }
};

onMounted(() => {
  tokenRefreshInterval = setInterval(checkAndUpdateToken, 30 * 60 * 1000);
});

onUnmounted(() => {
  if (tokenRefreshInterval) {
    clearInterval(tokenRefreshInterval);
  }
});
</script>

<style>
/* Import Inter font from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono&display=swap');

/* Reset and base styles */
*, *::before, *::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#app {
  display: flex;
  font-family: var(--font-body);
  width: 100%;
  min-height: 100vh;
  position: relative;
  color: var(--text-color);
  background-color: var(--background-color);
  transition: all var(--transition-speed) ease;
  line-height: 1.6;
}

/* Typography */
h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-heading);
  font-weight: 600;
  line-height: 1.2;
  color: var(--text-color);
  margin-bottom: 1rem;
}

h1 { font-size: 2.5rem; }
h2 { font-size: 2rem; }
h3 { font-size: 1.75rem; }
h4 { font-size: 1.5rem; }
h5 { font-size: 1.25rem; }
h6 { font-size: 1rem; }

p {
  margin-bottom: 1rem;
  color: var(--text-secondary-color);
}

a {
  color: var(--link-color);
  text-decoration: none;
  transition: color var(--transition-speed) ease;
}

a:hover {
  color: var(--link-hover-color);
}

/* Layout */
.content {
  flex: 1;
  margin-left: 80px;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  position: relative;
  z-index: 1;
  min-height: calc(100vh - 50px);
}

.background-logo {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80vh;
  opacity: var(--theme-logo-opacity, 0.2);
  z-index: 1;
  pointer-events: none;
  transition: opacity var(--transition-speed) ease;
}

.background-logo-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  opacity: inherit;
}

.content-wrapper {
  position: relative;
  z-index: 2;
  padding: 2rem;
  flex: 1;
  width: 100%;
  background-color: var(--overlay-color);
  box-shadow: var(--shadow-sm);
}

/* Cards and containers */
.card {
  background-color: var(--card-background);
  border-radius: 0.5rem;
  padding: 1.5rem;
  box-shadow: var(--shadow-sm);
  transition: box-shadow var(--transition-speed) ease;
}

.card:hover {
  box-shadow: var(--shadow-md);
}

/* Form elements */
input, select, textarea {
  background-color: var(--input-background);
  border: 1px solid var(--border-color);
  border-radius: 0.375rem;
  padding: 0.5rem 0.75rem;
  color: var(--text-color);
  transition: all var(--transition-speed) ease;
}

input:focus, select:focus, textarea:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 3px rgba(var(--primary-color), 0.1);
}

/* Buttons */
button {
  background-color: var(--primary-color);
  color: var(--button-text);
  border: none;
  border-radius: 0.375rem;
  padding: 0.5rem 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all var(--transition-speed) ease;
}

button:hover {
  filter: brightness(110%);
}

/* Media queries */
@media (max-width: 768px) {
  .content {
    margin-left: 0;
  }

  .background-logo {
    width: 90%;
    height: 70vh;
  }

  h1 { font-size: 2rem; }
  h2 { font-size: 1.75rem; }
  h3 { font-size: 1.5rem; }
  h4 { font-size: 1.25rem; }
  h5 { font-size: 1.125rem; }
  h6 { font-size: 1rem; }
}

@media (min-width: 769px) {
  .content {
    margin-left: 80px;
  }

  .background-logo {
    width: 80%;
    height: 80vh;
    max-width: calc(100vw - 100px);
    left: calc(50% + 40px);
  }
}

:root {
  /* Light theme defaults */
  --schedule-card-booked: #c8e6c9;
  --schedule-card-default: #ffffff;
  --schedule-card-disabled: #f5f5f5;
}

:root[data-theme="dark"] {
  /* Dark theme overrides */
  --schedule-card-booked: #2e7d32;
  --schedule-card-default: #1e293b;
  --schedule-card-disabled: #0f172a;
}

/* Add transition for theme changes */
* {
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Toast notification styles */
:deep(.Vue-Toastification__toast) {
  background-color: var(--toast-background) !important;
  color: var(--toast-text) !important;
  border: 1px solid var(--toast-border) !important;
  box-shadow: var(--shadow-md) !important;
}

:deep(.Vue-Toastification__toast--success) {
  background-color: var(--toast-success-background) !important;
}

:deep(.Vue-Toastification__toast--error) {
  background-color: var(--toast-error-background) !important;
}

:deep(.Vue-Toastification__toast--info) {
  background-color: var(--toast-info-background) !important;
}

:deep(.Vue-Toastification__toast--warning) {
  background-color: var(--toast-warning-background) !important;
}

:deep(.Vue-Toastification__close-button) {
  color: var(--toast-text) !important;
}

:deep(.Vue-Toastification__toast-body) {
  color: var(--toast-text) !important;
}

/* Add transition for theme changes */
:deep(.Vue-Toastification__toast) {
  transition: background-color var(--transition-speed) ease,
              color var(--transition-speed) ease,
              border-color var(--transition-speed) ease !important;
}
</style>
